import { useMemo, useState } from 'react';
import { ICustomerAddressResponse, ICustomerResponse } from '../../../api/dtos/customer';
import Tab, { ITabValue } from '../../ui/components/Tab/Tab';
import EmptyState from '../../../ui/components/States/Empty';
import AddCustomerAddress from './AddCustomerAddress';
import {
  useCustomerGetAnyAddresses,
  useCustomerGetTokenSummary,
  useCustomerGetWithdrawalAddresses,
} from '../../../api/customer';
import DigitalAssetSummary from './DigitalAssetSummary';
import CustomerAddressSummary from './CustomerAddressSummary';
import { Dropdown } from '../../../ui';
import { ArrowsClockwise } from '@phosphor-icons/react';
import Popover from '../../../ui/components/Popover/Popover';
import { UseInfiniteQueryResult } from 'react-query';
import { AxiosResponse } from 'axios';

interface ICustomerSummaryProps {
  customer: ICustomerResponse;
  depositAddressesQuery?: UseInfiniteQueryResult<AxiosResponse<ICustomerAddressResponse>>;
  showDigitalAssetSummary?: boolean;
  showAddressSummary?: boolean;
}

const CustomerSummary: React.FC<ICustomerSummaryProps> = ({
  customer,
  depositAddressesQuery,
  showDigitalAssetSummary = true,
  showAddressSummary = false,
}) => {
  const [tab, setTab] = useState<number>(0);
  const [valueType, setValueType] = useState<'digital' | 'usd'>('digital');
  const [isNewAddressAdded, setIsNewAddressAdded] = useState<boolean>(false);

  const tokenAddressQuery = useCustomerGetTokenSummary(encodeURIComponent(customer.customer_id), {
    enabled: showDigitalAssetSummary,
  });

  const withdrawalAddressesQuery = useCustomerGetWithdrawalAddresses(
    {
      customerId: encodeURIComponent(customer.customer_id),
    },
    {
      enabled: showAddressSummary,
    }
  );

  const anyAddressesQuery = useCustomerGetAnyAddresses(
    {
      customerId: encodeURIComponent(customer.customer_id),
    },
    {
      enabled: showAddressSummary,
    }
  );

  const tabs: ITabValue[] = useMemo(() => {
    return [
      { label: 'Digital Asset Summary', hidden: !showDigitalAssetSummary },
      {
        label: 'Deposit Address Summary',
        hidden: showAddressSummary ? depositAddressesQuery?.data?.pages[0]?.data?.count === 0 : true,
      },
      {
        label: 'Withdrawal Address Summary',
        hidden: showAddressSummary ? withdrawalAddressesQuery?.data?.pages[0]?.data?.count === 0 : true,
      },
      {
        label: 'User Address Summary',
        hidden: showAddressSummary ? anyAddressesQuery?.data?.pages[0]?.data?.count === 0 : true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositAddressesQuery, withdrawalAddressesQuery, anyAddressesQuery]);

  const onRefresh = () => {
    tokenAddressQuery?.refetch();
    depositAddressesQuery?.refetch();
    withdrawalAddressesQuery?.refetch();
    anyAddressesQuery?.refetch();
  };

  const isAddresses =
    depositAddressesQuery?.data?.pages[0]?.data?.results?.length ||
    withdrawalAddressesQuery?.data?.pages[0]?.data?.results?.length ||
    anyAddressesQuery?.data?.pages[0]?.data?.results?.length ||
    tokenAddressQuery?.data?.data?.results?.length;

  return isAddresses ? (
    <div className='rounded-md border'>
      <Tab
        values={tabs}
        type={'primary'}
        onChange={setTab}
        listClassName='border-b p-3 rounded-md'
        headerActions={
          <div className='flex'>
            <Popover
              containerClassName='my-auto'
              referenceContent={
                <ArrowsClockwise
                  onClick={onRefresh}
                  size={20}
                  weight='bold'
                  className='mx-2 cursor-pointer transition hover:rotate-180 hover:text-blue-600'
                />
              }
              isBasic
              popoverContent={'Refresh now'}
            />
            {tab === 0 && (
              <Dropdown
                value={{
                  label: (
                    <span>
                      Value in <strong>{valueType === 'digital' ? 'Digital Asset' : 'USD'}</strong>
                    </span>
                  ),
                  value: valueType,
                }}
                options={[
                  { label: 'Digital Asset', value: 'digital' },
                  { label: 'USD', value: 'usd' },
                ]}
                onChange={(val) => setValueType(val.value as 'digital' | 'usd')}
                optionsContainerClass='right-0'
                customClass='z-20 pl-3 pr-8'
                optionsCustomClass='px-3'
              />
            )}
            {showAddressSummary && <AddCustomerAddress customerId={customer.customer_id} type='add' />}
          </div>
        }>
        <DigitalAssetSummary
          assets={tokenAddressQuery?.data?.data?.results}
          isLoading={tokenAddressQuery.isLoading || tokenAddressQuery.isRefetching}
          valueType={valueType}
        />
        <CustomerAddressSummary addressQuery={depositAddressesQuery} type='deposit' />
        <CustomerAddressSummary addressQuery={withdrawalAddressesQuery} type='withdrawal' />
        <CustomerAddressSummary addressQuery={anyAddressesQuery} type='any' />
      </Tab>
    </div>
  ) : (
    !showDigitalAssetSummary && !!showAddressSummary && (
      <div className='flex flex-col justify-center rounded-md border'>
        <EmptyState message='No Addresses associated with this customer' />
        {isNewAddressAdded && (
          <div
            className='group mb-2 flex cursor-pointer justify-center text-sm hover:text-blue-600'
            onClick={onRefresh}>
            <ArrowsClockwise
              size={20}
              weight='bold'
              className='mx-2 my-auto transition group-hover:rotate-180'
            />
            Refresh
          </div>
        )}
        <AddCustomerAddress
          customerId={customer.customer_id}
          type='link'
          onSuccess={() => setIsNewAddressAdded(true)}
        />
      </div>
    )
  );
};

export default CustomerSummary;
