import { FC, useState } from 'react';
import { Button, ConfirmationModal } from '../../ui';
import { useMutation } from 'react-query';
import { addressApi } from '../../api/address';
import { transactionApi } from '../../api/transaction';
import { Trash } from '@phosphor-icons/react';
import { capitalize } from 'lodash';
import { toast } from '../../ui/components/Toast';
import { useRouter } from '../../modules/router/RouterProvider';
import { getTypeApi } from '../../utils/helpers/apiHelpers';
import { customerApi } from '../../api/customer';

interface IArchiveButtonProps {
  id: number | string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  identifier?: string;
  customerId?: string;
  type: 'address' | 'transaction' | 'customer';
}

const ArchiveButton: FC<IArchiveButtonProps> = (props) => {
  const { open, setOpen, identifier, id, customerId, type } = props;

  const [modal, setModal] = useState<boolean>(open || false);

  const { navigate } = useRouter();

  const onSuccess = () => {
    toast.success(`This ${capitalize(type)} has been Archived.`);
    navigate(`/${getTypeApi(type)}`);
  };

  const { mutate: archiveAddress } = useMutation(addressApi.archiveAddress, { onSuccess });
  const { mutate: archiveTransaction } = useMutation(transactionApi.archiveTransaction, { onSuccess });
  const { mutate: archiveCustomer } = useMutation(customerApi.archiveCustomer, { onSuccess });

  const getMessageInfo = () => {
    if (type === 'customer') {
      return `will not appear on your list of customers.`;
    }
    return `do not appear on your history of previous searches.`;
  };

  const ModalBody: FC = () => {
    return (
      <div>
        Are you sure you want to archive this {type}
        <strong className='break-words'> {identifier || id}</strong>
        {customerId && (
          <>
            {' '}
            of customer <strong>{customerId}</strong>
          </>
        )}
        ?
        <p>
          Archived {getTypeApi(type)} {getMessageInfo()}
        </p>
      </div>
    );
  };

  const onConfirm = () => {
    if (type === 'address') {
      archiveAddress(Number(id));
    } else if (type === 'transaction') {
      archiveTransaction(Number(id));
    } else {
      archiveCustomer(encodeURIComponent(id as string));
    }
    setOpen?.(false);
    setModal(false);
  };

  const onCancel = () => {
    setOpen?.(false);
    setModal(false);
  };

  return (
    <>
      {!setOpen && (
        <Button
          variant='tertiary'
          className='my-auto ml-2 px-3'
          onClick={() => {
            setOpen?.(true);
            setModal(true);
          }}>
          <Trash size={16} />
        </Button>
      )}
      <ConfirmationModal
        open={open ? open : modal}
        data={(open ? open : modal) || null}
        onCancel={onCancel}
        onConfirm={onConfirm}
        title={`Archive this ${capitalize(type)}`}
        body={<ModalBody />}
      />
    </>
  );
};

export default ArchiveButton;
