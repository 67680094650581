import { FC } from 'react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { formatDateTime, formatDateTimeHour } from '../../../utils/helpers/date';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import EntityWithSubtypeBadge from '../../ui/components/EntityTypeBadge';
import ReportTable from '../ReportTable';
import { RiskBadge } from '../../../ui';
import { IAddressResponse } from '../../../api/dtos/address';
import AddressBalanceWrapper from '../../Address/AddressMetadata/AddressBalanceWrapper';

interface IAddressDetailsReportProps {
  addressData: IAddressResponse;
  balance: { balance: number; balance_usd: number };
  attribution_timestamp: string;
  part?: number;
}

const AddressDetailsReport: FC<IAddressDetailsReportProps> = ({
  addressData,
  balance,
  attribution_timestamp,
  part,
}) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;
  const currentTime = formatDateTime(new Date().toLocaleString('en-US', { timeZone: timezone }));

  if (!addressData) return null;

  const tableData = [
    {
      label: 'Blockchain',
      value: (
        <CurrencyBadge currency={addressData.currency} label={addressData.currency_verbose} transparent />
      ),
    },
    {
      label: 'Customer ID',
      value: <div className='break-all'>{addressData.customer_id}</div>,
      isHidden: !addressData.customer_id,
    },
    ...(addressData.tag_name_verbose_owner && addressData.tag_type_verbose_owner
      ? [
          { label: 'Owner', value: addressData.tag_name_verbose_owner },
          {
            label: 'Owner Type',
            value: (
              <EntityWithSubtypeBadge
                type={addressData.tag_type_verbose_owner}
                subtype={addressData.tag_subtype_verbose_owner}
              />
            ),
          },
        ]
      : []),
    ...(addressData.tag_name_verbose && addressData.tag_type_verbose
      ? [
          { label: 'User', value: addressData.tag_name_verbose },
          {
            label: 'User Type',
            value: (
              <EntityWithSubtypeBadge
                type={addressData.tag_type_verbose}
                subtype={addressData.tag_subtype_verbose}
              />
            ),
          },
        ]
      : []),
    {
      label: 'Digital Assets List',
      value: [
        {
          label: 'Name',
          value: (
            <CurrencyBadge currency={addressData.currency} label={addressData.currency_verbose} transparent />
          ),
        },
        {
          label: 'Balance',
          value: (
            <AddressBalanceWrapper
              balance={balance.balance}
              balance_usd={balance.balance_usd}
              currency_short={addressData.currency_short}
            />
          ),
        },
      ],
    },
    {
      label: 'Risk Level',
      value: (
        <div>
          <RiskBadge risk={addressData.risk_level} />
        </div>
      ),
    },
    {
      label: 'First Transaction Time',
      value: (
        <span className='my-auto'>{formatDateTime(addressData.earliest_transaction_time, timezone)}</span>
      ),
    },
    {
      label: 'Latest Transaction Time',
      value: <span className='my-auto'>{formatDateTime(addressData.latest_transaction_time, timezone)}</span>,
    },
    {
      label: 'Attribution Timestamp',
      value: <span className='my-auto'>{formatDateTimeHour(attribution_timestamp)} (GMT+0)</span>,
      isHidden: !attribution_timestamp,
    },
  ];

  return (
    <div>
      <h1 className='mb-1 text-[26px] font-extrabold'>Address Report</h1>
      <code className='lead mb-3'>{addressData.identifier}</code>
      <hr className='my-2' />

      <div className='text-2xs'>
        <span className='mr-5 inline-block text-gray-500'>Report Exported On</span>
        {currentTime}
      </div>
      <hr className='my-2' />

      <ReportTable title={`Part ${part}: Address Details`} tableData={tableData} />
    </div>
  );
};

export default AddressDetailsReport;
