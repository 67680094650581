import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../utils/helpers/helperFunctions';

export const getModuleAndComponent = (url: string): string => {
  const modulePatterns = {
    AddrLst: [
      '^/risk/api/v4/addresses/$',
      '/direct-tag-data/name-verboses',
      '/update-alerts',
      '/export',
      '/monitoring',
    ],
    AddrDtl: [
      '/risk/api/v4/addresses/\\d+$',
      '/main-balance',
      '/token-summary',
      '/attribution-timestamp',
      '/tag-aggregated-incoming-values',
      '/tag-aggregated-outgoing-values',
      '/transactions',
      '/sentiment-summary',
      '/report',
    ],
    CustAddr: [
      '/risk/api/v4/customers/\\d+/deposit-addresses',
      '/risk/api/v4/customers/\\d+/withdrawal-addresses',
    ],
    Settings: [
      '/risk/api/v4/custom-tagged-addresses',
      '/risk/api/v4/custom-monitored-addresses',
      '/risk/api/v4/token-monitoring-addresses',
      '/profile/api/v2/algo-summary',
      '/risk/api/v4/monitoring-ignored-addresses',
      '/risk/api/v4/custom-monitored-entities',
      '/profile/api/v1/team-members/me',
      '/profile/api/v2/team-members',
      '/profile/api/v2/workflow-stages',
    ],
    TrnLst: [
      '^/risk/api/v4/transactions/$',
      '/risk/api/v4/transactions/export',
      '/risk/api/v4/transactions/update-alerts',
    ],
    TrnDtl: [
      '/risk/api/v4/transactions/\\d+$',
      '/aggregated-inputs',
      '/aggregated-outputs',
      '/inputs',
      '/outputs',
      '/external-transaction',
      '/internal-transactions',
      '/token-transfers',
      '/report',
    ],
    CustLst: [
      '^/risk/api/v4/customers/$',
      '/risk/api/v4/customers/export',
      '/risk/api/v4/customers/update-alerts',
    ],
    CustDtl: [
      '/risk/api/v4/customers/[^/]+$',
      '/balances',
      '/deposit-addresses',
      '/withdrawal-addresses',
      '/any-addresses',
      '/deposits-tag-aggregates',
      '/withdrawals-tag-aggregates',
      '/deposits',
      '/withdrawals',
      '/sentiment_summary',
      '/sentiments',
      '/pof-customer-summary',
      '/pof-address-summary',
    ],
    AddrPrev: [
      '/risk/api/v4/\\d+/address-preview/[^/]+$',
      '/address-preview/[^/]+/token-summary',
      '/address-preview/[^/]+/main-balance',
      '/address-preview/[^/]+/attribution-timestamp',
      '/address-preview/[^/]+/aggregated-inputs',
      '/address-preview/[^/]+/aggregated-outputs',
    ],
    Search: ['/risk/api/v4/search', '/risk/api/v4/search/suggest', '/risk/api/v4/search/customers'],
    Workflow: [
      '/risk/api/v4/[^/]+/workspace-assignment-counts',
      '/profile/api/v2/workspaces/workflow-stages',
      '/profile/api/v2/workspaces/members',
    ],
    Dashboard: [
      '/risk/api/v4/dashboard/addresses',
      '/risk/api/v4/dashboard/transactions',
      '/risk/api/v4/dashboard/customers',
      '/risk/api/v4/dashboard/entities',
      '/risk/api/v4/dashboard/cases',
      '/risk/api/v4/dashboard/rules',
      '/risk/api/v4/dashboard/alerts',
    ],
    Auth: [
      '/auth0/api/organization',
      '/auth0/api/logout',
      '/auth0/api/change-password',
      '/auth0/api/permissions',
    ],
    Profile: [
      '/profile/api/v1/team-members/me',
      '/profile/api/v1/team-members/me/notification-subscriptions',
      '/profile/api/v1/team-members/me/autoresolve-alert-levels',
      '/profile/api/v1/team-members/me/continuous-monitoring-settings',
    ],
    Download: ['/download'],
    Analytics: ['/account-permissions'],
    HealthCheck: ['/api/v4/health-check'],
    Integration: [
      '/integrations',
      '/integrations/webhook-audit-trail',
      '/integrations/fireblocks-logs',
      '/integrations/slack/auth',
      '/integrations/webhook/test',
    ],
    Billing: [
      '/profile/billing/plan',
      '/profile/billing/details',
      '/subscriptions/customer',
      '/profile/billing/invoices',
      '/profile/billing/credit-usage',
      '/profile/billing/credit-usage-nonunique',
    ],
    Workspaces: [
      '/profile/api/v2/workspaces',
      '/profile/api/v2/workspaces/lite',
      '/profile/api/v2/workspaces/members',
      '/profile/api/v2/workspaces/workflow-stages',
    ],
    Classifier: ['/risk/api/v4/classifiers'],
    Stages: ['/profile/api/v2/workflow-stages/lite', '/profile/api/v2/workflow-stages'],
    Case: [
      '/resolutioncenter/api/v4/cases',
      '/resolutioncenter/api/v4/cases/\\d+/comments',
      '/resolutioncenter/api/v4/cases/\\d+/addresses',
      '/resolutioncenter/api/v4/cases/\\d+/transactions',
      '/resolutioncenter/api/v4/cases/\\d+/customers',
      '/resolutioncenter/api/v4/cases/\\d+/audit-trail',
    ],
    Policy: [
      '/risk/api/v4/policies',
      '/risk/api/v4/policies/\\d+/rules',
      '/risk/api/v4/policies/\\d+/audit-trail',
      '/risk/api/v4/policies/\\d+/rules/\\d+/crypto-objects-flagged',
      '/risk/api/v4/policies/\\d+/rules/\\d+/alerts',
      '/risk/api/v4/policies/\\d+/classifiers',
      '/risk/api/v4/policies/\\d+/classifiers/\\d+',
      '/risk/api/v4/policies/\\d+/classifiers/\\d+/alerts-triggered-over-time',
      '/risk/api/v4/policies/download-rules',
    ],
    InvestigationTree: [
      '/risk/api/v4/addresses/\\d+/investigation-tree',
      '/risk/api/v4/transactions/investigated-transactions',
      '/risk/api/v4/\\d+/address-preview/\\d+/investigation-tree',
      '/risk/api/v4/\\d+/transaction-preview/\\d+/investigation-tree',
    ],
    Comment: ['/resolutioncenter/api/v4/\\w+/\\d+/comments'],
    Alert: [
      '/resolutioncenter/api/v4/alerts',
      '/resolutioncenter/api/v4/alerts/\\d+/cycles',
      '/resolutioncenter/api/v4/alerts/\\d+/items',
    ],
    DigitalAssets: ['/risk/api/v4/digital-assets', '/risk/api/v4/digital-assets/download'],
    MonitoringHealth: ['/risk/api/v4/addresses/rescreening-addresses'],
    Upload: ['/upload/api/v4/files', '/upload/api/v4/files/\\d+/download'],
    CustomTags: ['/risk/api/v4/custom-tags', '/risk/api/v4/custom-tags/\\d+/toggle'],
    TeamMembers: ['/profile/api/v2/team-members/search', '/profile/api/v2/team-members/bulk-invite'],
    MonitoringPreferences: [
      '/profile/api/v1/team-members/me/continuous-monitoring-settings',
      '/profile/api/v1/team-members/me/autoresolve-alert-levels',
    ],

    ApiKeys: ['/profile/api/v1/team-members/me/api-keys'],
  };

  for (const [module, patterns] of Object.entries(modulePatterns)) {
    if (patterns.some((pattern) => new RegExp(pattern).test(url))) {
      return module;
    }
  }

  return 'Unknown/Component';
};

export const handleCacheError = (
  error: AxiosError,
  variables: unknown,
  errorType: 'queryCache' | 'mutationCache'
) => {
  try {
    Sentry.withScope((scope) => {
      const moduleInfo = getModuleAndComponent(error?.config?.url || '');
      const statusCode = error?.response?.status || 'unknown_status';
      const simplifiedRoute = error?.config?.url?.split('?')[0];
      const errorMessage = getErrorMessage(error) || 'Unknown Err';

      // Format: Module | Method StatusCode | Route | Error Message
      const errorName = `${moduleInfo} | ${error?.config?.method} ${statusCode} | ${simplifiedRoute} | ${errorMessage}`;

      scope.setExtra('variables', variables);
      scope.setExtra('endpoint', error?.config?.url);
      scope.setExtra('requestMethod', error?.config?.method);
      scope.setLevel('error');
      scope.setTag('errorType', errorType);
      scope.setTag('statusCode', statusCode.toString());
      scope.setTag('module', moduleInfo.split('/')[0]);

      const errorEvent = new Error(errorMessage);
      errorEvent.name = errorName;
      errorEvent.stack = error.stack;

      Sentry.captureException(errorEvent, {
        fingerprint: [
          moduleInfo,
          error?.config?.method || 'unknown_method',
          statusCode.toString(),
          error?.config?.url?.split('?')[0] || 'unknown_url',
        ],
        extra: {
          originalError: error,
          errorResponse: error?.response?.data,
          timestamp: new Date().toISOString(),
          fullUrl: error?.config?.url,
        },
      });
    });
  } catch {
    Sentry.captureException(error);
  }
};
