import { Plus } from '@phosphor-icons/react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { Button, RiskBadge } from '../../../ui';
import Table from '../../../ui/components/Table/Table';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import DigitalAssets from '../../ui/components/Currency/DigitalAssets';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import CaseAddEntitiesModal from '../CaseAddEntitiesModal';
import { useState } from 'react';
import { ICaseCustomerResponse, ICaseResponse } from '../../../api/dtos/case';
import DeleteCaseEntity from './DeleteCaseEntity';
import { AxiosResponse } from 'axios';
import { UseInfiniteQueryResult } from 'react-query';

interface EntityTableProps {
  caseData: ICaseResponse;
  customersQuery?: UseInfiniteQueryResult<AxiosResponse<ICaseCustomerResponse>>;
}

const CustomersTable: React.FC<EntityTableProps> = ({ caseData, customersQuery }) => {
  const { navigate } = useRouter();

  const [modal, setModal] = useState(false);

  const [count, customers] = flattenInfiniteQueryResult(customersQuery?.data);

  if (!customers?.length && !customersQuery.isFetching) return null;

  const headerData = ['Risk', 'Currency', 'Name', 'Alerts', 'Deposit', 'Added By', ''];

  const rows = customers?.map((customer) => {
    return {
      id: customer.customer_id,
      data: [
        <RiskBadge key={customer.id} risk={customer.risk_level} />,
        <DigitalAssets key={customer.id} digitalAssets={customer.currencies.map((c) => ({ currency: c }))} />,
        <IdentifierEllipsis key={customer.id} identifier={customer.customer_id} copyable />,
        customer.alerts,
        <CurrencyValue key={customer.id} value={customer.deposit_amount} currency={'USD'} dualTone />,
        customer.added_by_name,
        <DeleteCaseEntity
          key={customer.id}
          type='customer'
          caseId={caseData.id}
          entityId={customer.id}
          entityLabel={customer.customer_id}
        />,
      ],
      onClick: async () => {
        navigate(`/customers/${encodeURIComponent(customer.customer_id)}`, {
          caseId: caseData.case_id.toString(),
          entityId: customer.id.toString(),
          refCase: caseData.id.toString(),
        });
      },
    };
  });

  return (
    <div className='mb-5'>
      <Table
        title='Customers'
        count={count}
        headerActions={
          <Button variant='tertiary' onClick={() => setModal(true)} iconStart={<Plus size={16} />}>
            Add
          </Button>
        }
        headerData={headerData}
        rows={rows}
        border
        height={200}
        {...buildInfiniteQueryTableProps(customersQuery)}
      />
      <CaseAddEntitiesModal
        caseId={caseData.id}
        type='customer'
        isOpen={modal}
        onClose={() => setModal(false)}
      />
    </div>
  );
};

export default CustomersTable;
