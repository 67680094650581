import { FC } from 'react';
import { useRouter } from '../../../modules/router/RouterProvider';
import { formatDateTime } from '../../../utils/helpers/date';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import CurrencyValue from '../../ui/components/Currency/CurrencyValue';
import ReportTable from '../ReportTable';
import { RiskBadge } from '../../../ui';
import { ITransactionResponse } from '../../../api/dtos/transaction';

interface ITransactionDetailsReportProps {
  transactionData: ITransactionResponse;
  part?: number;
}

const TransactionDetailsReport: FC<ITransactionDetailsReportProps> = ({ transactionData, part }) => {
  const { getQueryParams } = useRouter();
  const timezone = getQueryParams().tz;
  const currentTime = formatDateTime(new Date().toLocaleString('en-US', { timeZone: timezone }));

  if (!transactionData) return null;

  const value = () => {
    const showDash = transactionData.digital_assets.length > 1;
    const value =
      transactionData.digital_assets.length === 0
        ? transactionData.value
        : transactionData.digital_assets[0].value;
    const currency =
      transactionData.digital_assets.length === 0
        ? transactionData.currency_short
        : transactionData.digital_assets[0].token;
    const valueUsd =
      transactionData.digital_assets.length === 0
        ? transactionData.value_usd
        : transactionData.digital_assets[0].value_usd;
    return (
      <>
        <CurrencyValue key='value' value={showDash ? null : value} currency={currency} />
        <CurrencyValue key='valueUSD' value={showDash ? null : valueUsd} currency='USD' />
      </>
    );
  };

  const tableData = [
    {
      label: 'Blockchain',
      value: (
        <CurrencyBadge
          currency={transactionData.currency}
          label={transactionData.currency_verbose}
          transparent
        />
      ),
    },
    {
      label: 'Transaction Hash',
      value: <span className='my-auto'>{transactionData.identifier}</span>,
    },
    {
      label: 'Transaction Time',
      value: <span className='my-auto'>{formatDateTime(transactionData.block_date_time, timezone)}</span>,
    },
    {
      label: 'Risk Level',
      value: (
        <div>
          <RiskBadge risk={transactionData.risk_level} />
        </div>
      ),
    },
    {
      label: 'Total Transaction Value',
      value: value(),
    },
    {
      label: 'Transaction Fee',
      value: (
        <>
          <CurrencyValue
            className='col-start-2'
            key='value'
            value={transactionData.fee}
            currency={transactionData.currency_short}
          />
          <CurrencyValue key='valueUSD' value={transactionData.fee_usd} currency='USD' />,
        </>
      ),
    },
    ...(transactionData.customer_id
      ? [
          {
            label: 'Customer Id',
            value: <span className='my-auto'>{transactionData.customer_id}</span>,
          },
          {
            label: 'Address Type',
            value: (
              <span className='my-auto'>{transactionData.type === 1 ? 'Deposit' : 'Withdrawal'} Address</span>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <h1 className='mb-1 text-[26px] font-extrabold'>Transaction Report</h1>
      <code className='lead mb-3'>{transactionData.identifier}</code>
      <hr className='my-2' />

      <div className='text-2xs'>
        <span className='mr-5 inline-block text-gray-500'>Report Exported On</span>
        {currentTime}
      </div>
      <hr className='my-2' />

      <ReportTable title={`Part ${part}: Transaction Details`} tableData={tableData} />
    </div>
  );
};

export default TransactionDetailsReport;
