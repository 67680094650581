import * as React from 'react';
// import { useParams } from 'react-router-dom';

import Dropdown, { DropdownOption } from '../../ui/components/Dropdown/Dropdown';
import { IAddressResponse } from '../../api/dtos/address';
import {
  useAddressGetAddressTagAggregatedIncomingValues,
  useAddressGetAddressTagAggregatedOutgoingValues,
} from '../../api/address';

import { useTransactionGetAggregatedInputs, useTransactionGetAggregatedOutputs } from '../../api/transaction';
import {
  useTransactionGetAggregatedInputsLite,
  useTransactionGetAggregatedOutputsLite,
} from '../../api/transactionLite';

import AssociationRiskTable from '../Address/Overview/AssociationRiskTable';
import DonutChart from './DonutChart';
import { ITransactionResponse } from '../../api/dtos/transaction';
import CounterpartyFilter, { IFilters, defaultStateFilters } from '../Address/Overview/CounterpartyFilter';
import CounterpartyAppliedFilters from '../Address/Overview/CounterpartyFilter/CounterpartyAppliedFilters';
import { entitySubTypeIds, entityTypes } from '../../utils/helpers/entity';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useCustomerGetDepositTag, useCustomerGetWithdrawalTag } from '../../api/customer';
import { ICustomerResponse } from '../../api/dtos/customer';
import { ChartPieSlice, Table } from '@phosphor-icons/react';
import ErrorMessage from '../../ui/components/Error/Error';
import Popover from '../../ui/components/Popover/Popover';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../utils/helpers/helperFunctions';
import {
  useAddressGetAggregatedInputsLite,
  useAddressGetAggregatedOutputsLite,
} from '../../api/addressPreview';
interface Props {
  address?: IAddressResponse;
  transaction?: ITransactionResponse;
  customer?: ICustomerResponse;
  type: 'address' | 'transaction' | 'customer';
  defaultEntities?: string[];
  defaultEntitySubTypes?: string[];
  defaultEntityName?: string;
  popover?: boolean;
  showModes?: boolean;
  maxHeightClass?: string;
}

const options = [
  { label: 'By Entity Type', value: 'type' },
  { label: 'By Entity Subtype', value: 'subtype' },
  { label: 'By Entity Name', value: 'name' },
];

const entities = [...Object.keys(entityTypes), 'Unknown'];
const entitySubtypes = [...Object.values(entitySubTypeIds)];

const CounterpartySummary: React.FC<Props> = (props) => {
  const {
    address,
    transaction,
    customer,
    type,
    defaultEntities,
    defaultEntitySubTypes,
    defaultEntityName,
    popover,
    showModes = true,
    maxHeightClass,
  } = props;
  // If defaultEntities is not provided, it's normal counterparty and show both modes
  const showBothModes = showModes && !(defaultEntities || defaultEntitySubTypes || defaultEntityName);
  const [counterpartyMode, setCounterpartyMode] = React.useState<'table' | 'chart'>('table');
  const [error, setError] = React.useState<string>('');

  const onError = (err: AxiosError<{ error: string }>) => {
    const errorMessage = getErrorMessage(err);
    if (err.response?.status === 400 && typeof errorMessage === 'string') {
      setError(errorMessage);
    } else {
      setError('Error while loading the counterparty summary');
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = (data: any) => {
    if (!data?.data) {
      setError('Error while loading the counterparty summary');
    }
  };
  const incomingAddressQueryFull = useAddressGetAddressTagAggregatedIncomingValues(address?.id, {
    enabled: type === 'address' && !popover,
    onSuccess: onSuccess,
    onError: onError,
  });
  const outgoingAddressQueryFull = useAddressGetAddressTagAggregatedOutgoingValues(Number(address?.id), {
    enabled: type === 'address' && !popover,
    onSuccess: onSuccess,
    onError: onError,
  });
  const incomingQueryLite = useAddressGetAggregatedInputsLite(address?.identifier, address?.currency, {
    enabled: type === 'address' && !!popover,
    onSuccess: onSuccess,
    onError: onError,
  });
  const outgoingQueryLite = useAddressGetAggregatedOutputsLite(address?.identifier, address?.currency, {
    enabled: type === 'address' && !!popover,
    onSuccess: onSuccess,
    onError: onError,
  });

  const { data: incomingValuesAddressData, isLoading: incomingLoadingAddress } = popover
    ? incomingQueryLite
    : incomingAddressQueryFull;

  const { data: outgoingValuesAddressData, isLoading: outgoingLoadingAddress } = popover
    ? outgoingQueryLite
    : outgoingAddressQueryFull;

  const incomingValuesAddress = React.useMemo(
    () => incomingValuesAddressData?.data?.counter_party_summary,
    [incomingValuesAddressData]
  );
  const outgoingValuesAddress = React.useMemo(
    () => outgoingValuesAddressData?.data?.counter_party_summary,
    [outgoingValuesAddressData]
  );
  const { data: incomingValuesFullTransactionData, isLoading: incomingLoadingFullTransaction } =
    useTransactionGetAggregatedInputs(transaction?.id, {
      enabled: type === 'transaction' && !popover,
    });
  const { data: outgoingValuesFullTransactionData, isLoading: outgoingLoadingFullTransaction } =
    useTransactionGetAggregatedOutputs(transaction?.id, {
      enabled: type === 'transaction' && !popover,
    });
  const { data: incomingValuesTransactionLiteData, isLoading: incomingLoadingTransactionLite } =
    useTransactionGetAggregatedInputsLite(transaction?.identifier, transaction?.currency, {
      enabled: type === 'transaction' && !!popover,
    });
  const { data: outgoingValuesTransactionLiteData, isLoading: outgoingLoadingTransactionLite } =
    useTransactionGetAggregatedOutputsLite(transaction?.identifier, transaction?.currency, {
      enabled: type === 'transaction' && !!popover,
    });
  const incomingValuesTransactionData = popover
    ? incomingValuesTransactionLiteData
    : incomingValuesFullTransactionData;
  const outgoingValuesTransactionData = popover
    ? outgoingValuesTransactionLiteData
    : outgoingValuesFullTransactionData;
  const incomingLoadingTransaction = popover
    ? incomingLoadingTransactionLite
    : incomingLoadingFullTransaction;
  const outgoingLoadingTransaction = popover
    ? outgoingLoadingTransactionLite
    : outgoingLoadingFullTransaction;
  const incomingValuesTransaction = React.useMemo(
    () => incomingValuesTransactionData?.data?.counter_party_summary,
    [incomingValuesTransactionData]
  );
  const outgoingValuesTransaction = React.useMemo(
    () => outgoingValuesTransactionData?.data?.counter_party_summary,
    [outgoingValuesTransactionData]
  );

  const { data: incomingValuesCustomerData, isLoading: incomingLoadingCustomer } = useCustomerGetDepositTag(
    encodeURIComponent(customer?.customer_id),
    {
      enabled: type === 'customer',
    }
  );
  const { data: outgoingValuesCustomerData, isLoading: outgoingLoadingCustomer } =
    useCustomerGetWithdrawalTag(encodeURIComponent(customer?.customer_id), {
      enabled: type === 'customer',
    });

  const incomingValuesCustomer = React.useMemo(
    () => incomingValuesCustomerData?.data?.results,
    [incomingValuesCustomerData]
  );

  const outgoingValuesCustomer = React.useMemo(
    () => outgoingValuesCustomerData?.data?.results,
    [outgoingValuesCustomerData]
  );

  const incomingValues = incomingValuesAddress || incomingValuesTransaction || incomingValuesCustomer;
  const outgoingValues = outgoingValuesAddress || outgoingValuesTransaction || outgoingValuesCustomer;
  const incomingLoading = incomingLoadingAddress || incomingLoadingTransaction || incomingLoadingCustomer;
  const outgoingLoading = outgoingLoadingAddress || outgoingLoadingTransaction || outgoingLoadingCustomer;

  const [selectedAnalysisType, setSelectedAnalysisType] = React.useState<DropdownOption>({
    label: 'By Entity Type',
    value: 'type',
  });
  const [filters, setFilters] = React.useState<IFilters>(defaultStateFilters);

  React.useEffect(() => {
    if (defaultEntities) {
      setFilters((prev) => ({ ...prev, entities: defaultEntities }));
      setSelectedAnalysisType({ label: 'By Name', value: 'all' });
    }
    if (defaultEntitySubTypes) {
      setFilters((prev) => ({ ...prev, entitySubtypes: defaultEntitySubTypes }));
    }
    if (defaultEntityName) {
      setFilters((prev) => ({ ...prev, entityName: defaultEntityName }));
    }
  }, [defaultEntities, defaultEntityName, defaultEntitySubTypes]);

  const onApplyFilter = (newFilters: IFilters) => {
    setFilters(newFilters);
  };

  const selectedEntities = React.useMemo(() => {
    return entities.filter((value) => {
      return filters.entities?.map((e) => e.toLowerCase())?.includes(value?.toLowerCase());
    });
  }, [filters]);

  const selectedEntitySubtypes = React.useMemo(() => {
    return entitySubtypes.filter((value) => {
      return filters.entitySubtypes?.map((e) => e.toLowerCase())?.includes(value?.toLowerCase());
    });
  }, [filters]);

  const incomingValuesMuted = React.useMemo(() => {
    if (!incomingValues) return [];

    let filteredIncomingValues = incomingValues;

    if (selectedEntities.length !== 0 && incomingValues) {
      filteredIncomingValues = filteredIncomingValues.filter((item) => {
        const tagTypeVerbose = item.tag_type_verbose || 'Unknown';
        return selectedEntities.includes(tagTypeVerbose);
      });
    }
    if (selectedEntitySubtypes.length !== 0 && incomingValues) {
      filteredIncomingValues = filteredIncomingValues.filter((item) => {
        const tagSubTypeVerbose = item.tag_subtype_verbose || 'Unknown';
        return selectedEntitySubtypes.includes(tagSubTypeVerbose);
      });
    }
    if (filters.entityName) {
      filteredIncomingValues = filteredIncomingValues.filter((item) => {
        return item.tag_name_verbose?.toLowerCase().includes(filters.entityName.toLowerCase());
      });
    }
    return filteredIncomingValues;
  }, [filters.entityName, incomingValues, selectedEntities, selectedEntitySubtypes]);

  const outgoingValuesMuted = React.useMemo(() => {
    if (!outgoingValues) return [];

    let filteredOutgoingValues = outgoingValues;
    if (selectedEntities.length && outgoingValues) {
      filteredOutgoingValues = filteredOutgoingValues.filter((item) => {
        const tagTypeVerbose = item.tag_type_verbose || 'Unknown';
        return selectedEntities.includes(tagTypeVerbose);
      });
    }
    if (selectedEntitySubtypes.length !== 0 && outgoingValues) {
      filteredOutgoingValues = filteredOutgoingValues.filter((item) => {
        const tagSubTypeVerbose = item.tag_subtype_verbose || 'Unknown';
        return selectedEntitySubtypes.includes(tagSubTypeVerbose);
      });
    }
    if (filters.entityName) {
      filteredOutgoingValues = filteredOutgoingValues.filter((item) => {
        return item.tag_name_verbose?.toLowerCase().includes(filters.entityName.toLowerCase());
      });
    }
    return filteredOutgoingValues;
  }, [filters.entityName, outgoingValues, selectedEntities, selectedEntitySubtypes]);

  const getChartType = () => {
    if (type !== 'transaction') return null;
    if (!transaction.type) return null;
    return transaction.type === 1 ? 'deposit' : 'withdrawal';
  };
  const incomingNodeInfo =
    type === 'address'
      ? incomingValuesAddressData?.data?.node_info
      : incomingValuesTransactionData?.data?.node_info;

  const outgoingNodeInfo =
    type === 'address'
      ? outgoingValuesAddressData?.data?.node_info
      : outgoingValuesTransactionData?.data?.node_info;

  if (incomingLoading || outgoingLoading) {
    return (
      <div className='rounded-md border bg-white'>
        <Skeleton height={'100%'} count={10} containerClassName='p-2 w-full h-full block' />
      </div>
    );
  }

  if (error !== '') {
    return <ErrorMessage title='Counterparty Summary' body={error} />;
  }

  const chartTypeId = `${type}-${address?.currency || transaction?.currency || ''}-${address?.identifier || transaction?.identifier || customer?.customer_id}`;

  return (
    <div className='rounded-md border bg-white'>
      <div className={classNames('flex items-center justify-between border-b-[1px] px-3 py-1')}>
        <div className='flex'>
          <h4 className={classNames('my-auto text-xs font-medium uppercase text-gray-500')}>
            Counterparty Summary
          </h4>
          {!showBothModes && (
            <div className={`ml-2 flex rounded-xl border border-blue-200 bg-blue-50 p-0.5`}>
              <Popover
                referenceContent={
                  <Table
                    className={classNames(
                      counterpartyMode === 'table' ? 'bg-blue-600 text-white' : 'text-blue-600',
                      'mr-1 cursor-pointer rounded-full p-0.5'
                    )}
                    size={16}
                    weight={counterpartyMode === 'table' ? 'fill' : null}
                    onClick={() => setCounterpartyMode('table')}
                  />
                }
                popoverContent={'List View'}
                className='!z-999 text-sm text-gray-800'
                isBasic
              />
              <Popover
                referenceContent={
                  <ChartPieSlice
                    className={classNames(
                      counterpartyMode === 'chart' ? 'bg-blue-600 text-white' : 'text-blue-600',
                      'mr-1 cursor-pointer rounded-full p-0.5'
                    )}
                    size={16}
                    weight={counterpartyMode === 'chart' ? 'fill' : 'bold'}
                    onClick={() => setCounterpartyMode('chart')}
                  />
                }
                popoverContent={'Chart View'}
                className='!z-999 text-sm text-gray-800'
                isBasic
              />
            </div>
          )}
        </div>
        <div className='flex gap-1'>
          {!defaultEntities && (
            <Dropdown
              value={selectedAnalysisType}
              options={options}
              onChange={(val) => setSelectedAnalysisType(val)}
              customClass='z-10 pl-3 pr-8 pt-2.5 pb-[9px]'
              optionsCustomClass='px-3'
            />
          )}
          <CounterpartyFilter filters={filters} onApply={onApplyFilter} />
        </div>
      </div>
      <div>
        <CounterpartyAppliedFilters filters={filters} setFilters={onApplyFilter} />
        {(showBothModes || counterpartyMode === 'chart') && (
          <DonutChart
            id={chartTypeId}
            key={`pieChartType${selectedEntities.join()}${selectedAnalysisType}`}
            rowInputs={incomingValues}
            rowOutputs={outgoingValues}
            inputs={incomingValuesMuted}
            outputs={outgoingValuesMuted}
            selectedEntities={selectedEntities}
            incomingNodeInfo={incomingNodeInfo}
            outgoingNodeInfo={outgoingNodeInfo}
            isDestinationTag={false}
            isLoading={incomingLoading || outgoingLoading}
            group={selectedAnalysisType.value.toString()}
            inputsType={getChartType()}
            type={type}
          />
        )}
        {(showBothModes || counterpartyMode === 'table') && (
          <AssociationRiskTable
            inputs={incomingValuesMuted}
            outputs={outgoingValuesMuted}
            inputsType={getChartType()}
            maxHeightClass={maxHeightClass}
          />
        )}
      </div>
    </div>
  );
};

export default CounterpartySummary;
