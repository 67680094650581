import { FC, useState } from 'react';
import { BasicModal, Button, RadioButtons, RadioButton } from '../../../ui';
import { Plus } from '@phosphor-icons/react';
import { useMutation, useQueryClient } from 'react-query';
import { customerApi } from '../../../api/customer';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from '../../../ui/components/Form/InputController';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import CompassLoader from '../../ui/components/Loader/CompassLoader';
import startCase from 'lodash/startCase';
import { useAuth } from '../../../modules/auth';
import { IOption } from '../../Filters/CustomSelect';
import CurrencyDropdown from '../../ui/components/Select/CurrencyDropdown';
import { useWorkspace } from '../../../utils/helpers/common';

interface AddCustomerAddressProps {
  customerId: string;
  type: 'add' | 'link';
  onSuccess?: () => void;
}

const initialData = {
  currency: -1,
  identifier: '',
};

const AddCustomerAddress: FC<AddCustomerAddressProps> = (props) => {
  const { customerId, type, onSuccess: onSuccessProp } = props;

  const [addressType, setAddressType] = useState<'deposit' | 'withdrawal'>('deposit');
  const [currency, setCurrency] = useState<IOption>({
    value: '-1',
    label: 'All chains',
  });
  const typeLabel = startCase(addressType);
  const { state } = useAuth();
  const isAnyEnabled = useWorkspace()?.is_address_screening_type_any_enabled;

  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const validations = Yup.object({
    currency: Yup.number().label('Currency').required(),
    identifier: Yup.string().label('Identifier').required(),
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(validations),
  });

  const onSuccess = (data) => {
    onSuccessProp?.();
    setOpen(false);
    setValue('identifier', '');
    toast.success(data.data.message || data.data.info || 'Address added successfully');

    queryClient.invalidateQueries({
      queryKey: ['customerApi', customerId, 'getDepositAddresses'],
      refetchActive: true,
      refetchInactive: false,
    });
    queryClient.invalidateQueries({
      queryKey: ['customerApi', customerId, 'getWithdrawalAddresses'],
      refetchActive: true,
      refetchInactive: false,
    });
    queryClient.invalidateQueries({
      queryKey: ['customerApi', customerId, 'getAnyAddresses'],
      refetchActive: true,
      refetchInactive: false,
    });
  };

  const onError = (error: AxiosError) => {
    toast.error(getErrorMessage(error));
  };

  const { mutate, isLoading } = useMutation(
    addressType === 'deposit' ? customerApi.addDepositAddress : customerApi.addWithdrawalAddress,
    {
      onSuccess,
      onError,
    }
  );

  const { mutate: addAnyAddress, isLoading: isAnyLoading } = useMutation(customerApi.addAnyAddress, {
    onSuccess,
    onError,
  });

  const onSubmit = (data: typeof initialData) => {
    if (isAnyEnabled) {
      addAnyAddress({
        customer_id: encodeURIComponent(customerId),
        identifier: data.identifier,
        currency: -1,
      });
    } else {
      mutate({
        customer_id: encodeURIComponent(customerId),
        identifier: data.identifier,
        currency: Number(currency?.value || -1),
      });
    }
  };

  const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressType(event.target.value as 'deposit' | 'withdrawal');
  };

  return (
    <div className={classNames(type === 'link' ? 'mx-auto' : 'mx-2')}>
      {!state.userProfile?.skip_alert_creation_modes?.includes(1) && (
        <Button
          variant={type === 'add' ? 'primary' : 'tertiary'}
          className={classNames('mx-auto font-semibold', { 'mb-4 text-sm': type === 'link' })}
          onClick={() => setOpen(true)}>
          {type === 'add' ? (
            <>
              <Plus color='#fff' className='mr-2 rounded-xl' size={16} /> Add Address
            </>
          ) : (
            <>
              <Plus color='#000' className='mr-2 rounded-xl bg-gray-200' size={16} /> Link Address
            </>
          )}
        </Button>
      )}
      <BasicModal
        open={open}
        onClose={() => setOpen(false)}
        modalTitle={`Add Address`}
        modalBody={
          isLoading || isAnyLoading ? (
            <CompassLoader />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {!isAnyEnabled && (
                <RadioButtons
                  name='type'
                  selected={addressType}
                  className={classNames('mb-4')}
                  onChange={onChangeType}>
                  <RadioButton value='deposit' labelText='Deposit Address' />
                  <RadioButton value='withdrawal' labelText='Withdrawal Address' />
                </RadioButtons>
              )}
              <div className='mb-2 text-sm'>Customer {isAnyEnabled ? 'User' : typeLabel} Address</div>
              <div className='flex rounded-md border p-0.5'>
                {!isAnyEnabled && (
                  <CurrencyDropdown currency={currency} setCurrency={setCurrency} addAllChainsOption={true} />
                )}
                <InputController
                  id={'identifier'}
                  type={'text'}
                  name={'identifier'}
                  control={control}
                  className='my-auto ml-1 w-full'
                  inputClassNames='border-0'
                  placeholder='Enter Address'
                />{' '}
              </div>
              {isAnyEnabled && (
                <div className='mt-2 text-[13px] text-gray-500'>
                  Note : For this wallet, all chains will be screened and linked to the current customer
                </div>
              )}
              <div className='mt-8 flex justify-end'>
                <Button variant='tertiary' onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button type='submit' variant='primary' className='ml-2'>
                  Add {!isAnyEnabled && typeLabel} Address
                </Button>
              </div>
            </form>
          )
        }
      />
    </div>
  );
};

export default AddCustomerAddress;
