import { useMemo, useState } from 'react';
import { BasicBadge, Button, ConfirmationModal, Input } from '../../../ui';
import { startCase } from 'lodash';
import SingleSelect, { IOption } from '../../ui/components/Select/SingleSelect';
import { toast } from 'react-toastify';
import { Plus } from '@phosphor-icons/react';
import CurrencyBadge from '../../ui/components/Badge/CurrencyBadge';
import { UseInfiniteQueryResult, useMutation, useQueryClient } from 'react-query';
import { customerApi } from '../../../api/customer';
import { AxiosError, AxiosResponse } from 'axios';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';
import { Currencies } from '../../../utils/constants/constants';
import CompassLoader from '../../ui/components/Loader/CompassLoader';
import CurrencyDropdown from '../../ui/components/Select/CurrencyDropdown';
import { ICustomerAddressResponse } from '../../../api/dtos/customer';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';

interface IAddDepositWithdrawalProps {
  customerId: string;
  type: 'deposit' | 'withdrawal';
  depositAddressesQuery: UseInfiniteQueryResult<AxiosResponse<ICustomerAddressResponse>>;
}

const AddDepositWithdrawal: React.FC<IAddDepositWithdrawalProps> = ({
  customerId,
  type,
  depositAddressesQuery,
}) => {
  const [modal, setModal] = useState(false);
  const [address, setAddress] = useState<string>('');
  const [txHash, setTxHash] = useState<string>('');
  const [error, setError] = useState<string>('');

  const queryClient = useQueryClient();

  const currencyOptions = Currencies.map((c) => ({
    label: c.currency_short,
    value: c.value.toString(),
  }));

  const [currency, setCurrency] = useState<IOption>(currencyOptions[0]);

  const addressValue = useMemo(
    () => (address ? { value: address, label: address, id: currency.value } : null),
    [address, currency]
  );

  const resetForm = () => {
    setAddress(null);
    setTxHash('');
    setError('');
  };

  const { mutate, isLoading } = useMutation(customerApi.addDepositWithdrawal, {
    onSuccess: () => {
      resetForm();
      setModal(false);
      queryClient.invalidateQueries([
        'customerApi',
        customerId,
        type === 'deposit' ? 'getDeposits' : 'getWithdrawals',
      ]);
      toast.success(`Successfully added ${startCase(type)}`);
    },
    onError: (error: AxiosError) => {
      toast.error(getErrorMessage(error));
    },
  });

  const onConfirm = () => {
    if (!address || !txHash) {
      setError('Please fill in all fields');
      return;
    }
    setError('');
    mutate({
      address: address,
      customerId: encodeURIComponent(customerId),
      type,
      currency: Number(currency.value),
      transaction: txHash,
    });
  };

  interface IAddressOption extends IOption {
    id?: string;
  }

  const [, addresses] = flattenInfiniteQueryResult(depositAddressesQuery?.data);

  const addressesOptions = addresses.map((address) => ({
    id: address.currency.toString(),
    label: address.identifier,
    value: address.identifier,
  }));

  const getCustomOptionLabel = (option: IOption) => {
    return (
      <BasicBadge>
        <CurrencyBadge
          currency={Number((option as IAddressOption).id)}
          label={option.label}
          className='text-gray-900'
        />
      </BasicBadge>
    );
  };

  return (
    <>
      <Button variant='primary' onClick={() => setModal(true)}>
        <Plus size={16} weight='bold' className='mr-2' /> Add {startCase(type)}
      </Button>
      <ConfirmationModal
        open={!!modal}
        data={modal || null}
        onCancel={() => {
          setModal(false);
          resetForm();
        }}
        onConfirm={onConfirm}
        title={`Add ${startCase(type)}`}
        confirmButton={`Add ${startCase(type)}`}
        confirmButtonClass={isLoading ? 'opacity-50 pointer-events-none' : ''}
        cancelButtonClass={isLoading ? 'opacity-50 pointer-events-none' : ''}
        body={
          isLoading ? (
            <CompassLoader />
          ) : (
            <div>
              <div className='mb-2'>Select Address</div>
              {type === 'deposit' ? (
                <SingleSelect
                  value={addressValue}
                  options={addressesOptions}
                  handleChange={(option: IAddressOption) => {
                    setAddress(option?.value);
                    setCurrency(currencyOptions.find((o) => o.value === option?.id));
                  }}
                  getCustomOptionLabel={getCustomOptionLabel}
                  getCustomLabel={getCustomOptionLabel}
                  {...buildInfiniteQueryTableProps(depositAddressesQuery)}
                  asyncPaginate
                />
              ) : (
                <div className='flex rounded-md border p-0.5'>
                  <CurrencyDropdown currency={currency} setCurrency={setCurrency} />
                  <Input
                    id={'identifier'}
                    type={'text'}
                    name={'identifier'}
                    className='my-auto ml-1 w-full'
                    inputClassNames='border-0'
                    placeholder='Enter Address'
                    value={address}
                    onChange={(e) => setAddress((e.target as HTMLInputElement).value)}
                  />
                </div>
              )}
              <div className='mb-2 mt-4'>{startCase(type)} Transaction Hash</div>
              <Input
                id='txn-hash'
                type={'text'}
                value={txHash}
                onChange={(e) => setTxHash((e.target as HTMLInputElement).value)}
              />
              {error && (
                <p className='mt-2 text-sm text-red-600' id='email-error'>
                  {error}
                </p>
              )}
            </div>
          )
        }
      />
    </>
  );
};

export default AddDepositWithdrawal;
