import { useState } from 'react';
import { customerApi, useCustomerGetDeposits, useCustomerGetWithdrawals } from '../../../api/customer';
import Table from '../../../ui/components/Table/Table';
import IncomingOutgoingSwitch from '../../ui/components/Table/IncomingOutgoingSwitch';
import {
  buildInfiniteQueryTableProps,
  flattenInfiniteQueryResult,
} from '../../../utils/helpers/react-query.helper';
import IdentifierEllipsis from '../../ui/components/Currency/IdentifierEllipsis';
import DigitalAssets from '../../ui/components/Currency/DigitalAssets';
import { formatDateTime } from '../../../utils/helpers/date';
import RisksDetected from '../../common/RisksDetected';
import { UseInfiniteQueryResult, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import AddDepositWithdrawal from './AddDepositWithdrawal';
import { ICustomerAddressResponse, ICustomerResponse } from '../../../api/dtos/customer';
import CustomerTransactionsFilter, { IFilters, defaultStateFilters } from './CustomerTransactionsFilter';
import CustomerTransactionsAppliedFilters from './CustomerTransactionsAppliedFilters';
import CurrenciesButtons from './CurrenciesButtons';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import OriginatorBeneficiary from '../../common/OriginatorBeneficiary';
import TransactionValue from '../../Transaction/TransactionValue';
import { useRouter } from '../../../modules/router/RouterProvider';
import { useAuth } from '../../../modules/auth';
import { AxiosResponse } from 'axios';
import { IRiskTypePayload } from '../../../components/common/Alerts/Alert/AlertInfo/AlertInfo';
import { isAllUndefined } from '../../../utils/helpers/helperFunctions';

interface ICustomerTransactionsProps {
  customerId: string;
  customer?: ICustomerResponse;
  depositAddressesQuery?: UseInfiniteQueryResult<AxiosResponse<ICustomerAddressResponse>>;
  noEmptySpaceHeight?: boolean;
  params?: IRiskTypePayload;
}

const CustomerTransactions: React.FC<ICustomerTransactionsProps> = ({
  customerId,
  customer,
  depositAddressesQuery,
  noEmptySpaceHeight = false,
  params,
}) => {
  const [transactionType, setTransactionType] = useState<'incoming' | 'outgoing'>('incoming');
  const [filters1, setFilters1] = useState(
    params
      ? {
          ...defaultStateFilters,
          transaction_end_date: params?.riskType1Payload?.transactionEndTime,
          transaction_start_date: params?.riskType1Payload?.transactionStartTime,
          tag_type_verboses: params?.riskType1Payload?.tag_type_verboses,
          tag_subtype_verboses: params?.riskType1Payload?.tag_subtype_verboses,
          tag_name_verboses: params?.riskType1Payload?.tag_name_verboses,
        }
      : (defaultStateFilters as IFilters)
  );
  const [filters2, setFilters2] = useState(
    params
      ? {
          ...defaultStateFilters,
          transaction_end_date: params?.riskType2Payload?.transactionEndTime,
          transaction_start_date: params?.riskType2Payload?.transactionStartTime,
          tag_type_verboses: params?.riskType2Payload?.tag_type_verboses,
          tag_subtype_verboses: params?.riskType2Payload?.tag_subtype_verboses,
          tag_name_verboses: params?.riskType2Payload?.tag_name_verboses,
        }
      : (defaultStateFilters as IFilters)
  );
  const [currency, setCurrency] = useState<number>(null);
  const [dateOrdering, setDateOrdering] = useState<'transaction_time' | '-transaction_time'>(
    '-transaction_time'
  );
  const { navigate } = useRouter();
  const { state } = useAuth();
  const queryParams1 = {
    customerId: encodeURIComponent(customerId),
    currency,
    ordering: dateOrdering,
    identifier: params?.riskType1Payload?.identifier,
    incoming_value_usd__gte: params?.riskType1Payload?.incoming_value_usd__gte,
    ...filters1,
  };
  const queryParams2 = {
    customerId: encodeURIComponent(customerId),
    currency,
    ordering: dateOrdering,
    identifier: params?.riskType2Payload?.identifier,
    incoming_value_usd__gte: params?.riskType2Payload?.incoming_value_usd__gte,
    ...filters2,
  };
  const depositsQuery = useCustomerGetDeposits(queryParams1, {
    enabled: params ? !isAllUndefined(params?.riskType1Payload) : true,
  });

  const withdrawalsQuery = useCustomerGetWithdrawals(queryParams2, {
    enabled: params ? !isAllUndefined(params?.riskType2Payload) : true,
  });

  const transactionQuery = transactionType === 'incoming' ? depositsQuery : withdrawalsQuery;

  const [, transactions] = flattenInfiniteQueryResult(transactionQuery.data);

  const onSwitchChange = (value: 'incoming' | 'outgoing') => {
    window.scrollTo(0, 0);
    setTransactionType(value);
  };

  const headerData = [
    'Transaction',
    'Deposit Address',
    'Digital Assets',
    <div
      className='inline-flex cursor-pointer'
      key='date'
      onClick={() =>
        setDateOrdering(dateOrdering === 'transaction_time' ? '-transaction_time' : 'transaction_time')
      }>
      Date{' '}
      {dateOrdering === 'transaction_time' ? (
        <CaretUp weight='bold' className='ml-2 text-gray-900' />
      ) : (
        <CaretDown weight='bold' className='ml-2 text-gray-900' />
      )}
    </div>,
    'Entities Detected',
    'Risk Level',
    'Volume',
    'Value (in US$)',
  ];

  const rows = transactions.map((transaction) => {
    const key = transaction.id + transaction.address;
    // let entities = transactionType === 'incoming' ? transaction.from_addresses : transaction.to_addresses;
    // entities = entities.filter((e) => e.exposure_type !== 'indirect');
    return {
      id: transaction.id,
      currency: transaction.currency,
      data: [
        <IdentifierEllipsis key={key} identifier={transaction.identifier} clickable copyable />,
        <IdentifierEllipsis
          key={key}
          type='addresses'
          identifier={transaction.address}
          currency={transaction.currency}
          customerId={transaction.customer_id}
          customerType={transactionType === 'incoming' ? 1 : 2}
          isCreateCaseDisabled
          clickable
          copyable
        />,
        <DigitalAssets key={key} digitalAssets={transaction.digital_assets} />,
        formatDateTime(transaction.transaction_time, state?.userProfile?.timezone),
        <OriginatorBeneficiary
          key={key}
          transaction={transaction}
          isInCustomerDetails
          type={transactionType === 'incoming' ? 'originator' : 'beneficiary'}
        />,
        <RisksDetected key={key} risk={transaction.risk_level} risksDetected={transaction.risks_detected} />,
        <TransactionValue key={key} transaction={transaction} type='value' />,
        <TransactionValue key={key} transaction={transaction} type='value_usd' />,
      ],
    };
  });

  const { mutate } = useMutation(customerApi.exportDepositWithdrawal, {
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
  });
  const onExport = () => {
    mutate({
      customerId: encodeURIComponent(customerId),
      type: transactionType === 'incoming' ? 'deposits' : 'withdrawals',
      filters: transactionType === 'incoming' ? filters1 : filters2,
    });
  };

  const onClickRow = (id: string) => {
    navigate(`/transactions/${id}`);
  };

  const calculateEmptySpaceHeight = (rowCount: number): number => {
    if (rowCount === 0) return 0;
    if (rowCount >= 5) return 0;
    if (rowCount === 1) return 150;

    return 150 - 25 * (rowCount + 1);
  };

  const showBothModes = () => {
    return isAllUndefined(params?.riskType1Payload) && isAllUndefined(params?.riskType2Payload);
  };

  return (
    <div className={`${customer?.digital_assets?.length === 0 && 'rounded border'}`}>
      {customer?.digital_assets?.length !== 0 && (
        <div className='mt-2'>
          <CurrenciesButtons
            currency={currency}
            setCurrency={setCurrency}
            digitalAssets={customer?.digital_assets}
          />
        </div>
      )}
      <Table
        title={transactionType === 'incoming' ? 'Deposits' : 'Withdrawals'}
        rows={rows}
        headerData={headerData}
        className={customer?.digital_assets?.length !== 0 && 'rounded border'}
        headerActionsLeft={
          (!params || params?.showBothModes || showBothModes()) && (
            <IncomingOutgoingSwitch onChange={onSwitchChange} />
          )
        }
        onExport={onExport}
        appliedFilters={
          <CustomerTransactionsAppliedFilters
            filters={transactionType === 'incoming' ? filters1 : filters2}
            setFilters={transactionType === 'incoming' ? setFilters1 : setFilters2}
          />
        }
        onClickRow={onClickRow}
        headerActions={
          <>
            <CustomerTransactionsFilter
              filters={transactionType === 'incoming' ? filters1 : filters2}
              onApply={transactionType === 'incoming' ? setFilters1 : setFilters2}
            />
            {!state.userProfile?.skip_alert_creation_modes?.includes(1) && depositAddressesQuery && (
              <AddDepositWithdrawal
                customerId={customerId}
                type={transactionType === 'incoming' ? 'deposit' : 'withdrawal'}
                depositAddressesQuery={depositAddressesQuery}
              />
            )}
          </>
        }
        {...buildInfiniteQueryTableProps(transactionQuery)}
        height={noEmptySpaceHeight ? 450 : 300}
        showEmptySpaceHeight={!noEmptySpaceHeight}
        emptySpaceHeight={noEmptySpaceHeight ? undefined : calculateEmptySpaceHeight(rows?.length || 0)}
        customEmptyStateHeight={noEmptySpaceHeight ? undefined : 80}
      />
    </div>
  );
};

export default CustomerTransactions;
